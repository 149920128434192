import { React, useState, useEffect } from "react";
import Connection from "./Connection";
import Question from "./Question";
import ApplyApplicant from "./ApplyApplicant";
import dayjs from "dayjs";
import { TbArrowsMaximize } from "react-icons/tb";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import community_banner from "../asset/form/community_banner.PNG";

const pnumHypen = function (str) {
  str = str.replace(/[^0-9]/g, "");
  let tmp = "";
  if (str.length < 4) {
    return str;
  } else if (str.length < 8) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 4);
    tmp += "-";
    tmp += str.substr(7);
    return tmp;
  }
};

const birthHyphen = function (str) {
  str = str.replace(/[^0-9]/g, "");
  let tmp = "";
  if (str.length < 5) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 4);
    tmp += ".";
    tmp += str.substr(4);
    return tmp;
  } else {
    tmp += str.substr(0, 4);
    tmp += ".";
    tmp += str.substr(4, 2);
    tmp += ".";
    tmp += str.substr(6);
    return tmp;
  }

  return str;
};

function FormQuestion() {
  const [questionList, setQuestionList] = useState([]);
  const [questionNum, setQuestionNum] = useState(-1);
  const [progress, setProgress] = useState(0);
  const [recruit, setRecruit] = useState({});

  const [completed, setCompleted] = useState(false);

  const [isFinalDisabled, setIsFinalDisabled] = useState(false);

  useEffect(() => {
    setProgress(
      questionList.length > 0 && (questionNum + 1) / questionList.length
    );
  }, [questionNum]);

  const param = window.location.href.split("/");
  const club_id = param[param.length - 3];
  const recruit_id = param[param.length - 2];

  const [isClosed, setIsClosed] = useState(false);
  const today = new Date();

  async function getRecruit() {
    const res = await Connection("/getRecruit", {
      club_id: club_id,
      recruit_id: recruit_id,
    });
    setRecruit(res);
    setIsClosed(res.state !== 1);
    setQuestionList(JSON.parse(res.question).filter((q, q_idx) => q_idx > 3));
  }

  useState(() => {
    getRecruit();
  }, []);

  const [profile, setProfile] = useState({
    name: sessionStorage.getItem("name") && sessionStorage.getItem("name"),
    phone:
      sessionStorage.getItem("phone") &&
      pnumHypen(sessionStorage.getItem("phone")),
    gender:
      sessionStorage.getItem("gender") && sessionStorage.getItem("gender"),
    birthday:
      sessionStorage.getItem("birthday") &&
      birthHyphen(sessionStorage.getItem("birthday")),
  });

  const [answer, setAnswer] = useState(new Array(questionList.length));

  useEffect(() => {
    setAnswer(
      Array.from({ length: questionList.length }, (q, i) => ({
        id: questionList[i].id,
        value: "",
        option_idx: -1,
        multi_idx: [],
      }))
    );
  }, [questionList.length]);

  const [fileList, setFileList] = useState([]);

  const showDefaultQuestion = (
    <>
      <div className="div-form-question-section-01">
        <img
          className="img-form-question-section-01"
          src={sessionStorage.getItem("image")}
          alt=""
        />
      </div>
      <div className="div-form-question-section-02 H3M">
        지원자님 안녕하세요!👋🏻
        <br />
        아래 정보를 입력해주세요!
      </div>
      <div className="div-form-question-section-03">
        <div className="div-form-question-section-03-01">
          <div className="div-form-question-default-q Sub3M">이름</div>
          <input
            className="input-form-question-section Body4M"
            value={profile["name"]}
            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
            placeholder="김동들"
          />
        </div>
        <div className="div-form-question-section-03-01">
          <div className="div-form-question-default-q Sub3M">성별</div>
          <div className="div-form-question-section-03-a">
            <button
              className={
                profile["gender"] === "male"
                  ? "button-form-question-section-03-a-selected Sub3M"
                  : "button-form-question-section-03-a Sub3M"
              }
              onClick={() => setProfile({ ...profile, gender: "male" })}
            >
              남
            </button>
            <button
              className={
                profile["gender"] === "female"
                  ? "button-form-question-section-03-a-selected Sub3M"
                  : "button-form-question-section-03-a Sub3M"
              }
              onClick={() => setProfile({ ...profile, gender: "female" })}
            >
              여
            </button>
          </div>
        </div>
      </div>
      <div className="div-form-question-section-04">
        <div className="div-form-question-default-q Sub3M">생년월일</div>
        <input
          className="input-form-question-section Body4M"
          value={profile["birthday"]}
          placeholder="2000.01.01"
          onChange={(e) =>
            setProfile({ ...profile, birthday: birthHyphen(e.target.value) })
          }
          maxLength="10"
        />
      </div>
      <div className="div-form-question-section-04">
        <div className="div-form-question-default-q Sub3M">전화번호</div>
        <input
          className="input-form-question-section Body4M"
          value={profile["phone"]}
          placeholder="010-0000-0000"
          onChange={(e) =>
            setProfile({ ...profile, phone: pnumHypen(e.target.value) })
          }
          maxLength="13"
        />
      </div>
    </>
  );

  const [isOverflown, setIsOverflown] = useState(false);
  const [showWholeQuestion, setShowWholeQuestion] = useState(false);

  useEffect(() => {
    const element = document.getElementById("question-title");
    element && setIsOverflown(element.scrollHeight > element.clientHeight);
  }, [questionNum]);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      console.log("Advertise is pushed");
    } catch (e) {
      console.error("AdvertiseError", e);
    }
  }, [questionNum, completed]);

  return (
    <div className="div-form-mobile-section">
      {showWholeQuestion && (
        <div className="div-modal-background">
          <div className="div-question-whole-question-section">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="div-question-num Sub1M" style={{ marginTop: 0 }}>
                Q{questionNum + 1}.
                <div className="div-question-keyword Tag1M">
                  {questionList[questionNum].k}
                </div>
              </div>
              <img
                className="div-click"
                src={Icon_Close}
                alt=""
                onClick={() => setShowWholeQuestion(false)}
              />
            </div>
            <pre className="pre-question-whole-question Sub2M">
              {questionList[questionNum].q}
            </pre>
          </div>
        </div>
      )}
      {isClosed ? (
        recruit.end_date &&
        dayjs(today).format("YYYYMMDD") <
          dayjs(recruit.end_date).format("YYYYMMDD") ? (
          <div className="div-form-question-background">
            <div
              className="div-form-question-section H3M"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="H3M" style={{ marginBottom: "90px" }}>
                모집이 조기 마감되었습니다.😭
              </div>
            </div>
          </div>
        ) : (
          <div className="div-form-question-background">
            <div
              className="div-form-question-section H3M"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="H3M" style={{ marginBottom: "90px" }}>
                모집이 마감되었습니다.😥
              </div>
            </div>
          </div>
        )
      ) : dayjs(today).format("YYYYMMDD") <
        dayjs(recruit.start_date).format("YYYYMMDD") ? (
        <div className="div-form-question-background">
          <div
            className="div-form-question-section H3M"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="H3M" style={{ marginBottom: "90px" }}>
              모집 시작 전입니다.😎
            </div>
          </div>
        </div>
      ) : dayjs(today).format("YYYYMMDD") >
        dayjs(recruit.end_date).format("YYYYMMDD") ? (
        <div className="div-form-question-background">
          <div
            className="div-form-question-section H3M"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="H3M" style={{ marginBottom: "90px" }}>
              모집이 마감되었습니다.😥
            </div>
          </div>
        </div>
      ) : (
        <div className="div-form-question-background">
          {!completed ? (
            <div className="div-form-question-section" id="question-section">
              {/* 질문 부분 */}
              <div className="div-form-question-contents-section">
                {questionNum === -1 ? (
                  showDefaultQuestion
                ) : (
                  <>
                    <div className="div-form-question-progressbar-section">
                      <div className="div-form-question-progressbar">
                        <div
                          className="div-form-question-progressbar-black"
                          style={{ width: progress * 100 + "%" }}
                        />
                      </div>
                    </div>
                    {questionList.length > 0 &&
                      questionList.map(
                        (q, idx) =>
                          idx === questionNum && (
                            <>
                              <div className="div-question-num Sub1M">
                                Q{idx + 1}.
                                <div className="div-question-keyword Tag1M">
                                  {q.k}
                                </div>
                              </div>
                              {q.type !== 22 && (
                                <pre
                                  className="pre-question-title H3M"
                                  id="question-title"
                                >
                                  {q.q}
                                </pre>
                              )}
                              {q.type !== 22 && isOverflown && (
                                <button
                                  className="button-question-title-show-whole Tag1M"
                                  onClick={() => setShowWholeQuestion(true)}
                                >
                                  <TbArrowsMaximize size="16" /> 전체 질문 보기
                                </button>
                              )}
                              <Question
                                questionNum={questionNum}
                                question={q}
                                answer={answer}
                                setAnswer={setAnswer}
                                fileList={fileList}
                                setFileList={setFileList}
                              />
                            </>
                          )
                      )}
                  </>
                )}
              </div>
              {/* 버튼 부분 */}
              <div
                className="div-form-apply-section"
                style={{
                  justifyContent: questionNum === 0 ? "right" : "space-between",
                  flex: "45 45",
                }}
              >
                {questionNum !== -1 && (
                  <button
                    className="button-form-question-pre Sub3M"
                    onClick={() => {
                      setQuestionNum(questionNum - 1);
                      setShowWholeQuestion(false);
                      document
                        .getElementById("question-section")
                        .scrollTo(0, 0);
                    }}
                  >
                    이전
                  </button>
                )}
                {progress === 1 ? (
                  <button
                    className="button-form-question-next-last Sub3M"
                    disabled={isFinalDisabled}
                    onClick={async () => {
                      if (!completed) {
                        var notAnswered = [];
                        answer.filter((a, idx) => {
                          a.value === "" && notAnswered.push(idx);
                        });
                        if (notAnswered.length > 0) {
                          window.alert(
                            "아직 답변하지 않은 질문이 있습니다.\n\n" +
                              notAnswered.map((na) => " Q" + (na + 1))
                          );
                        } else {
                          if (
                            window.confirm("지원서 제출을 완료하시겠습니까?")
                          ) {
                            if (
                              recruit_id ===
                              "986cd456-4e3d-43a5-8ba8-f1f1fa297a49"
                            ) {
                              setCompleted(true);
                            } else {
                              setIsFinalDisabled(true);
                              ApplyApplicant(
                                club_id,
                                recruit_id,
                                answer,
                                profile,
                                fileList,
                                setCompleted,
                                setIsFinalDisabled
                              );
                            }
                          }
                        }
                      }
                    }}
                  >
                    지원서 제출
                  </button>
                ) : questionNum === -1 ? (
                  <button
                    className="button-form-question-next Sub3M"
                    onClick={() => {
                      if (answer.length > 0) {
                        document
                          .getElementById("question-section")
                          .scrollTo(0, 0);
                        if (
                          profile.name &&
                          profile.gender &&
                          profile.birthday &&
                          profile.phone
                        ) {
                          setQuestionNum(questionNum + 1);
                        } else {
                          window.alert("아직 작성하지 않은 정보가 있습니다.");
                        }
                      }
                    }}
                    style={{ width: "100%" }}
                  >
                    지원서 작성하러 가기
                  </button>
                ) : (
                  <button
                    className="button-form-question-next Sub3M"
                    onClick={() => {
                      setQuestionNum(questionNum + 1);
                      setShowWholeQuestion(false);
                      document
                        .getElementById("question-section")
                        .scrollTo(0, 0);
                    }}
                  >
                    다음
                  </button>
                )}
              </div>
              <div className="div-form-adfit">
                <ins
                  className="adsbygoogle"
                  style={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    display: "block",
                    textAlign: "center",
                  }}
                  data-ad-client="ca-pub-1409157934399349"
                  data-ad-slot="4676234889"
                  data-ad-format="fluid"
                  data-full-width-responsive=""
                  data-ad-layout-key="-h5+44-1i-7h+j4"
                />
              </div>
            </div>
          ) : (
            <div
              className="div-form-question-section H3M"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              {/* <img
                src={community_banner}
                alt=""
                style={{
                  height: "360px",
                  borderRadius: "8px",
                  marginTop: "50px",
                  marginBottom: "60px",
                }}
              /> */}
              <div className="H3M">지원이 완료되었어요!👏🏻</div>
              <div className="div-form-question-final-section">
                <div className="Body4M">
                  본 지원폼 서비스는
                  <br />
                  동들에서 제공하였습니다.
                </div>
                <button
                  className="button-form-question-final Sub3M"
                  onClick={() => {
                    window.open("https://dongdle.com");
                  }}
                >
                  👉 동들 알아보러 가기 👈
                </button>
                <div className="div-form-adfit">
                  <ins
                    className="adsbygoogle"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      display: "block",
                      textAlign: "center",
                    }}
                    data-ad-client="ca-pub-1409157934399349"
                    data-ad-slot="4676234889"
                    data-ad-format="fluid"
                    data-full-width-responsive=""
                    data-ad-layout-key="-h5+44-1i-7h+j4"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FormQuestion;
