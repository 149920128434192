import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import KakaoLogin from "./components/KakaoLogin";
import Header from "./components/Header";
import Main from "./components/Main";
import Manage from "./components/Manage";
import Recruit from "./components/Recruit";
import HeaderM from "./components/HeaderM";
import Calendar from "./components/Calendar";
import FormIntro from "./components/FormIntro";
import FormQuestion from "./components/FormQuestion";
import HeaderMain from "./components/HeaderMain";
import Terms from "./components/Terms";
// import Error from "./components/Error";
import Privacy from "./components/Privacy";
import MakeForm from "./components/MakeForm";
import TimeTable from "./components/TimeTable";
import TimeTableModal from "./components/TimeTableModal";
import Message from "./components/MessageOut";
import FormTime from "./components/FormTime";
import FormKakao from "./components/FormKakao";
import TimePicker from "./components/TimePicker";
import MainM from "./components/MainM";
import HeaderMainM from "./components/HeaderMainM";
import TermsM from "./components/TermsM";
import PrivacyM from "./components/PrivacyM";
import ViewFormM from "./components/ViewFormM";
import SelectM from "./components/SelectM";
import Test from "./components/testguide.js";
import ClubList from "./components/community/ClubList";
import Navigation from "./components/community/Navigation";
import ClubMain from "./components/community/ClubMain";
import MyClub from "./components/community/MyClub";
import ManageMain from "./components/ATSM/ManageMain";
import EditRecruit from "./components/ATSM/EditRecruit";
import MyPageMain from "./components/community/MyPageMain";
import LikedClubs from "./components/community/LikedClubs";
import Home from "./components/community/Home";
import Protected from "./components/community/Protected";
import CreateClub from "./components/ATSM/CreateClub";
import Macle from "./components/Macle.js";
import MacleM from "./components/MacleM.js";

function App() {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const path = window.location.pathname;
  const param = window.location.href.split("/");
  const isMainM = useMediaQuery({
    query: "(max-width: 950px)",
  });

  return (
    <>
      {/* 서버 문제시 사용하도록 */}
      {/* <Error /> */}
      {param[4] === "clubs" && <HeaderM />}
      {param[3] !== "3d" &&
        param[3] !== "macle" &&
        param[3] !== "community" &&
        !path.includes("kakao") &&
        (param[3] === "formtime" || param[3] === "form" || path === "/ftest" ? (
          <HeaderM />
        ) : path === "/" ||
          path === "/privacy" ||
          path === "/terms" ||
          path === "/privacym" ||
          path === "/termsm" ||
          path === "/select" ? (
          isMobile ? (
            <HeaderMainM />
          ) : (
            <HeaderMain />
          )
        ) : (
          path === "/mypage" || path === "/gyudol" || isMobile || <Header />
        ))}

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            exact={true}
            element={isMainM ? <MainM /> : <Main />}
          />
          <Route
            path="/:club_id/:recruit_id"
            element={isMobile ? <ManageMain /> : <Manage />}
          />
          <Route path="/recruit" element={<Recruit />} />
          <Route path="/form/:club_id/:recruit_id" element={<FormIntro />} />
          <Route
            path="/form/:club_id/:recruit_id/question"
            element={<FormQuestion />}
          />

          <Route path="/createclub" element={<CreateClub />} />
          <Route path="/mypage" element={<ManageMain />} />
          <Route path="/:club_id/manage" element={<ManageMain />} />
          <Route path="/:club_id/recruitlist" element={<ManageMain />} />

          <Route path="/calendar" element={<Calendar />} />
          <Route path="/kakaologin" element={<KakaoLogin />} />
          <Route path="/formkakao" element={<FormKakao />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="/termsm" element={<TermsM />} />
          <Route path="/privacym" element={<PrivacyM />} />

          <Route path="/makeForm" element={<MakeForm />} />
          <Route path="/table" element={<TimeTable />} />
          <Route path="/message" element={<Message />} />
          <Route path="/tt" element={<TimeTableModal />} />
          <Route
            path="/formtime/:recruit_id/:applicant_id"
            element={<FormTime />}
          />
          <Route path="/tt" element={<TimeTableModal />} />
          <Route path="/picker" element={<TimePicker />} />
          <Route path="/view" element={<ViewFormM />} />
          <Route path="/select" element={<SelectM />} />
          <Route path="/test" element={<Test />} />

          <Route
            path="/community/home/:board_num/*"
            element={<Protected element={Home} />}
            exact={true}
          />
          <Route
            path="/community/club"
            element={<Protected element={ClubList} />}
          />

          <Route
            path="/community/club/:club_id/:menu/:board_num/*"
            element={<Protected element={ClubMain} />}
          />
          <Route
            path="/community/myclub/:club_id/:menu/:board_num/*"
            element={<Protected element={MyClub} />}
          />
          <Route
            path="/community/mypage"
            element={<Protected element={MyPageMain} />}
          />
          <Route path="/gyudol" element={<Protected element={LikedClubs} />} />
          <Route path="/editrecruit" element={<EditRecruit />} />

          <Route path="/macle" element={isMobile ? <MacleM /> : <Macle />} />
        </Routes>
      </BrowserRouter>
      {param[3] === "community" && <Navigation />}
    </>
  );
}

export default App;
